import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _710f1a70 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _5da3d980 = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _3d2b7295 = () => interopDefault(import('../pages/member/index.vue' /* webpackChunkName: "pages/member/index" */))
const _58825549 = () => interopDefault(import('../pages/member/complete/index.vue' /* webpackChunkName: "pages/member/complete/index" */))
const _63a55306 = () => interopDefault(import('../pages/member/confirm/index.vue' /* webpackChunkName: "pages/member/confirm/index" */))
const _ce097c72 = () => interopDefault(import('../pages/mypage/contact/index.vue' /* webpackChunkName: "pages/mypage/contact/index" */))
const _712986be = () => interopDefault(import('../pages/mypage/contract/index.vue' /* webpackChunkName: "pages/mypage/contract/index" */))
const _a9d6506e = () => interopDefault(import('../pages/mypage/hospital/index.vue' /* webpackChunkName: "pages/mypage/hospital/index" */))
const _4e5e29c2 = () => interopDefault(import('../pages/mypage/lovot/index.vue' /* webpackChunkName: "pages/mypage/lovot/index" */))
const _176f4868 = () => interopDefault(import('../pages/mypage/myaccount/index.vue' /* webpackChunkName: "pages/mypage/myaccount/index" */))
const _3b08d322 = () => interopDefault(import('../pages/mypage/news/index.vue' /* webpackChunkName: "pages/mypage/news/index" */))
const _4ee04880 = () => interopDefault(import('../pages/mypage/official-supporters/index.vue' /* webpackChunkName: "pages/mypage/official-supporters/index" */))
const _23390352 = () => interopDefault(import('../pages/mypage/contact/complete/index.vue' /* webpackChunkName: "pages/mypage/contact/complete/index" */))
const _68d85f90 = () => interopDefault(import('../pages/mypage/contact/confirm/index.vue' /* webpackChunkName: "pages/mypage/contact/confirm/index" */))
const _7aa2d5e8 = () => interopDefault(import('../pages/mypage/contact/history/index.vue' /* webpackChunkName: "pages/mypage/contact/history/index" */))
const _da1741e6 = () => interopDefault(import('../pages/mypage/contract/billing/index.vue' /* webpackChunkName: "pages/mypage/contract/billing/index" */))
const _6f6d7182 = () => interopDefault(import('../pages/mypage/contract/billing_address/index.vue' /* webpackChunkName: "pages/mypage/contract/billing_address/index" */))
const _4d490266 = () => interopDefault(import('../pages/mypage/contract/creditcard/index.vue' /* webpackChunkName: "pages/mypage/contract/creditcard/index" */))
const _3b184026 = () => interopDefault(import('../pages/mypage/hospital/detail/index.vue' /* webpackChunkName: "pages/mypage/hospital/detail/index" */))
const _6e3c94e6 = () => interopDefault(import('../pages/mypage/lovot/owners/index.vue' /* webpackChunkName: "pages/mypage/lovot/owners/index" */))
const _be592b62 = () => interopDefault(import('../pages/mypage/lovot/update/index.vue' /* webpackChunkName: "pages/mypage/lovot/update/index" */))
const _28e3df0d = () => interopDefault(import('../pages/mypage/myaccount/change/index.vue' /* webpackChunkName: "pages/mypage/myaccount/change/index" */))
const _6faba5d9 = () => interopDefault(import('../pages/mypage/myaccount/confirm/index.vue' /* webpackChunkName: "pages/mypage/myaccount/confirm/index" */))
const _2aca414a = () => interopDefault(import('../pages/mypage/contact/history/detail/index.vue' /* webpackChunkName: "pages/mypage/contact/history/detail/index" */))
const _143424b3 = () => interopDefault(import('../pages/mypage/contract/billing_address/change/index.vue' /* webpackChunkName: "pages/mypage/contract/billing_address/change/index" */))
const _2337d61a = () => interopDefault(import('../pages/mypage/contract/billing_address/confirm/index.vue' /* webpackChunkName: "pages/mypage/contract/billing_address/confirm/index" */))
const _efa337ae = () => interopDefault(import('../pages/mypage/contract/billing/detail/index.vue' /* webpackChunkName: "pages/mypage/contract/billing/detail/index" */))
const _20c59d08 = () => interopDefault(import('../pages/mypage/contract/creditcard/change/index.vue' /* webpackChunkName: "pages/mypage/contract/creditcard/change/index" */))
const _7401a73e = () => interopDefault(import('../pages/mypage/contract/creditcard/confirm/index.vue' /* webpackChunkName: "pages/mypage/contract/creditcard/confirm/index" */))
const _a4cf7b98 = () => interopDefault(import('../pages/mypage/contact/history/detail/complete/index.vue' /* webpackChunkName: "pages/mypage/contact/history/detail/complete/index" */))
const _8e0ec68a = () => interopDefault(import('../pages/mypage/contact/history/detail/confirm/index.vue' /* webpackChunkName: "pages/mypage/contact/history/detail/confirm/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _710f1a70,
    name: "404"
  }, {
    path: "/error",
    component: _5da3d980,
    name: "error"
  }, {
    path: "/member",
    component: _3d2b7295,
    name: "member"
  }, {
    path: "/member/complete",
    component: _58825549,
    name: "member-complete"
  }, {
    path: "/member/confirm",
    component: _63a55306,
    name: "member-confirm"
  }, {
    path: "/mypage/contact",
    component: _ce097c72,
    name: "mypage-contact"
  }, {
    path: "/mypage/contract",
    component: _712986be,
    name: "mypage-contract"
  }, {
    path: "/mypage/hospital",
    component: _a9d6506e,
    name: "mypage-hospital"
  }, {
    path: "/mypage/lovot",
    component: _4e5e29c2,
    name: "mypage-lovot"
  }, {
    path: "/mypage/myaccount",
    component: _176f4868,
    name: "mypage-myaccount"
  }, {
    path: "/mypage/news",
    component: _3b08d322,
    name: "mypage-news"
  }, {
    path: "/mypage/official-supporters",
    component: _4ee04880,
    name: "mypage-official-supporters"
  }, {
    path: "/mypage/contact/complete",
    component: _23390352,
    name: "mypage-contact-complete"
  }, {
    path: "/mypage/contact/confirm",
    component: _68d85f90,
    name: "mypage-contact-confirm"
  }, {
    path: "/mypage/contact/history",
    component: _7aa2d5e8,
    name: "mypage-contact-history"
  }, {
    path: "/mypage/contract/billing",
    component: _da1741e6,
    name: "mypage-contract-billing"
  }, {
    path: "/mypage/contract/billing_address",
    component: _6f6d7182,
    name: "mypage-contract-billing_address"
  }, {
    path: "/mypage/contract/creditcard",
    component: _4d490266,
    name: "mypage-contract-creditcard"
  }, {
    path: "/mypage/hospital/detail",
    component: _3b184026,
    name: "mypage-hospital-detail"
  }, {
    path: "/mypage/lovot/owners",
    component: _6e3c94e6,
    name: "mypage-lovot-owners"
  }, {
    path: "/mypage/lovot/update",
    component: _be592b62,
    name: "mypage-lovot-update"
  }, {
    path: "/mypage/myaccount/change",
    component: _28e3df0d,
    name: "mypage-myaccount-change"
  }, {
    path: "/mypage/myaccount/confirm",
    component: _6faba5d9,
    name: "mypage-myaccount-confirm"
  }, {
    path: "/mypage/contact/history/detail",
    component: _2aca414a,
    name: "mypage-contact-history-detail"
  }, {
    path: "/mypage/contract/billing_address/change",
    component: _143424b3,
    name: "mypage-contract-billing_address-change"
  }, {
    path: "/mypage/contract/billing_address/confirm",
    component: _2337d61a,
    name: "mypage-contract-billing_address-confirm"
  }, {
    path: "/mypage/contract/billing/detail",
    component: _efa337ae,
    name: "mypage-contract-billing-detail"
  }, {
    path: "/mypage/contract/creditcard/change",
    component: _20c59d08,
    name: "mypage-contract-creditcard-change"
  }, {
    path: "/mypage/contract/creditcard/confirm",
    component: _7401a73e,
    name: "mypage-contract-creditcard-confirm"
  }, {
    path: "/mypage/contact/history/detail/complete",
    component: _a4cf7b98,
    name: "mypage-contact-history-detail-complete"
  }, {
    path: "/mypage/contact/history/detail/confirm",
    component: _8e0ec68a,
    name: "mypage-contact-history-detail-confirm"
  }, {
    path: "*",
    component: _710f1a70,
    name: "notfound"
  }, {
    path: "/",
    component: _4e5e29c2,
    name: "lovot"
  }, {
    path: "/license",
    beforeEnter: (to, from, next) => {
          // Put the full page url including the protocol http(s) below
          window.location = 'https://lovot.life/terms/license/'
        }
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
